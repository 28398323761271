import { getLoggedInUserId } from './../actions/authAction';
import { postApiWrapper } from './token-wrapper-function';

export const deleteMarriageEdge = async (post_data) => {
    const loggedInNodeId = getLoggedInUserId();
    const data = {
        ...post_data,
        action: "delete_marriage_node",
        logged_in_node_id: loggedInNodeId,
        device_info: {
            appVersion: "Web",
        }
    };
    const result = await postApiWrapper(`/api/tasks/run_actions.php`, data);
    console.log('Result from deleteMarriageEdge', result);
    return result;
}

export const deleteNodeEdge = async (nodeId) => {
    const loggedInNodeId = getLoggedInUserId();
    const data = {
        action: "delete_node",
        logged_in_node_id: loggedInNodeId,
        node_id_to_delete: nodeId,
        device_info: {
            appVersion: "Web",
        }
    };
    const result = await postApiWrapper(`/api/tasks/run_actions.php`, data);
    console.log('Result from deleteNodeEdge', result);
    return result;
}

export const deleteParentalEdge = async (nodeId) => {
    const loggedInNodeId = getLoggedInUserId();
    const data = {
        logged_in_node_id: loggedInNodeId,
        action: "delete_parental_edge",
        kid_node_id: nodeId,
        device_info: {
            appVersion: "Web",
        }
    };
    const result = await postApiWrapper(`/api/tasks/run_actions.php`, data);
    console.log('Result from deleteParentalEdge', result);
    return result;
}

