import React from 'react'
import { toast } from 'react-toastify';
import { deleteParentalEdge } from '../../actions/deleteNodeAction';
import ToastMessage from './toast-message';

export default function DeleteParent ({setLoading,selectedNode = []}){

    const handleDeleteParent = async () => {
    
        setLoading(true);
    
        // const isSpouse = selectedPath.length == 0 ? true : false;
    
        try{
          const res = await deleteParentalEdge(selectedNode.uid);
          // getFamilyDetails(selectedNode,selectedPath,isSpouse);
          console.log(res);
          setLoading(false);
          if(res.status){
            toast.success(`Parental Edge Deletion Successful for ${selectedNode.name}`);
            toast.info(<ToastMessage message = {'Click Okay to reload page to reflect changes'} refreshRequired = {true}/>,{autoClose: false});
          }
          else
            toast.error(`Parental Edge Deletion Failed for ${selectedNode.name}\n Error: ${res.error_message}`);
        }catch(error){
          console.log(`Parental Edge Deletion Failed for ${selectedNode.name}`,error);
          toast.error(`Parental Edge Deletion Failed for ${selectedNode.name}\n Error: ${error}`);
          setLoading(false);
        }
      }

  return (
    <button 
    onClick = {()=>
     { 
      if (window.confirm(`Are you sure you want to remove parental edge of ${selectedNode.name}`))
      {
        handleDeleteParent()
      }
      }
    }
    >Delete Parent Edge
    </button>
  )
}
