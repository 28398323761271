import React from 'react'

export default function ToastMessage ({ closeToast, message,refreshRequired = false }) {
  
  const handleClick = () => {
    closeToast();
    window.location.reload();
  }
  
  return (
    <div>
        {message}
    <br/>
    <button onClick={refreshRequired ? handleClick : closeToast} style={{backgroundColor: '#07bc0c', color:'white',border:'none',padding:'5px 8px'}}>Okay</button>
    </div>
  )
}
